import baseComponent from "@/scripts/baseComponent";
import moment from 'moment';
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { API_CALL_STATUS } from "@/constant/config";
import FixedRentalAdd from "../../../views/components/FixedRental/FixedRentalAdd";

export default {

    extends: baseComponent,

    components: {
        FixedRentalAdd,
    },

    props: {
        request: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            defaultData: [],
            listData: [],
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {
        if (this.request.username) {
            this.searchUser();
        }
        console.log(this.isLoading)
    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.searchUser();
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Back
        back() {
            this.$emit("processingEvent", { event: "processingPage", data: "information" });
        },

        // Next
        next() {
            this.request.listData = this.listData.filter(x => x.isChecked === true);
            this.$emit("processingEvent", { event: "processingPage", data: "settings" });
        },

        getHoursAdded(){
            let hours = 0;
            switch (this.request.period)
            {
                case 5: //Period.THREE_HOUR:
                    hours = this.request.periodUnit * 3;
                    break;
                case 4: //Period.SIX_HOUR:
                    hours = this.request.periodUnit * 6;
                    break;
                case 3: //Period.DAY:
                    hours = this.request.periodUnit * 24;
                    break;
                case 1: //Period.WEEK:
                    hours = this.request.periodUnit * 7 * 24;
                    break;
                case 2: //Period.MONTH:
                    hours = this.request.periodUnit * 30 * 24;
                    break;
            }
            return hours;
        },

        // Search user
        searchUser() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.listData = [];
            if (this.request.username !== '') {
                fixedRentalApi.getMachineListByUsername({ username: this.request.username.replace(/\s/g, ''), fixedRentalId: 0 })
                    .then((res) => {
                        try {
                            res.data.forEach(element => {
                                element.activated = true;
                                element.isChecked = false;
                                element.startTime = this.request.startTime;
                                element.endTime = moment(element.startTime).add(this.getHoursAdded(), 'hours');
                            });
                            this.defaultData = res.data;
                            this.listData = res.data;
                            this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                        } catch (error) {
                            console.log("getData -> error", error)
                            this.feedStatusLoading(API_CALL_STATUS.FAILED);
                            // this.$store.dispatch("common/showUnkownError", error, "Error");
                        }
                    })
                    .catch(error => {
                        try {
                            if (error.name === 'Error') {
                                // if (this.$route.path !== '/login') {
                                //     setTimeout(() => {
                                //         location.href = "/login";
                                //     }, 2000);
                                // }
                            } else {
                                switch (error.errorCode) {
                                    default:
                                        console.log("getData -> error", error)
                                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                                        break;
                                }
                            }
                        } catch (error) {
                            console.log("getData -> error", error)
                            // this.$store.dispatch("common/showUnkownError", error, "Error");
                        }
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    });
            }
            else {
                this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
            }
        },
    }
} 