import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { mapState } from "vuex";
import moment from 'moment';
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import CreateFixedRentalInformation from "../../views/components/FixedRental/CreateFixedRentalInformation";
import CreateFixedRentalSelection from "../../views/components/FixedRental/CreateFixedRentalSelection";
import CreateFixedRentalSettings from "../../views/components/FixedRental/CreateFixedRentalSettings";

export default {

    extends: baseComponent,

    components: {
        CreateFixedRentalInformation,
        CreateFixedRentalSelection,
        CreateFixedRentalSettings,
    },

    props: {
    },

    data() {
        return {
            step: 'information',
            request: {
                username: '',
                packageId: null,
                amount: 0,
                point: 0,
                bonusPoint: 0,
                note: '',
                period: 5,
                periodUnit: 1,
                startTime: moment(),
                listData: [],
                isUsingBonuspoint: false,
                customChargePoint: false,
                forcedCharged: false,
                deviceInformation: '',
            },

            createFixedRentalInformationKey: 0,
            createFixedRentalSelectionKey: 0,
            createFixedRentalSettingsKey: 0,
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Processing page
        processingPage(page) {
            switch (page) {
                case 'information':
                    this.createFixedRentalInformationKey++;
                    break;
                case 'add':
                    this.createFixedRentalSelectionKey++;
                    break;
                case 'settings':
                    this.createFixedRentalSettingsKey++;
                    break;
            }
            this.step = page;
        },
    }
} 