import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import { mapState } from "vuex";
import moment from 'moment';
import FixedRentalSettings from "../../../views/components/FixedRental/FixedRentalSettings";
import { API_CALL_STATUS } from "@/constant/config";
import EventBus from "@/event/EventBus";

export default {

    components: {
        FixedRentalSettings,
    },

    extends: baseComponent,

    props: {
        request: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            startTime: moment(),
            endTime: moment().add(7, 'days'),
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {

    },

    methods: {

        // Back
        back() {
            this.$emit("processingEvent", { event: "processingPage", data: "add" });
        },

        // Create new
        create() {
            this.request.deviceInformation = JSON.stringify(this.getInformationClient());
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            fixedRentalApi.fixedRentalCreateNew(this.request)
                .then((res) => {
                    try {
                        this.postCreateNewSuccess();
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // POST create new __ SUCCESS
        postCreateNewSuccess() {
            setTimeout(() => {
                this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                this.closePopup('fixed-rental-create-new');
                EventBus.$emit("fixed-rental-perform-action", { action: 'refresh' });
                this.notificationSuccess(
                    "Success !",
                    "The changes have been applied successfully"
                );
            }, 500);
        },
    }
} 