import baseComponent from "@/scripts/baseComponent";
import fixedRentalApi from '@/api/gpuHub/fixedRental';
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
import { mapState } from "vuex";
import moment from 'moment';
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import FixedRentalAdd from "../../../views/components/FixedRental/FixedRentalAdd";

export default {

    extends: baseComponent,

    components: {
        FixedRentalAdd,
    },

    props: {
        request: {
            type: Object,
            default: {}
        },
    },

    data() {
        return {
            listData: []
        };
    },

    computed: {
        ...mapState({
            listServicePack: (state) => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {
        if (this.request.username) {
            this.searchUser();
        }
    },

    methods: {

        // Processing page
        processingPage() {
            this.request.listData = this.listData.filter(x => x.isChecked === true);
            this.$emit("processingEvent", { event: "processingPage", data: "add" });
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.searchUser();
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },
    }
} 